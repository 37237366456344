import { useState } from "react"
import { Button } from "antd"
import Layout from "@/layouts/Default"
import ButtonCreateHealthResults from "@/components/ButtonCreateHealthResults"
import ModalHealthResults from "@/components/HealthResults/ModalPreview"
import TableHealthResults from "@/components/TableHealthResults"
import { Page, Header, Actions } from "./styles"

const Element = () => {
  const [visibleModalHealthResults, setVisibleModalHealthResults] = useState(false)
  const [dataSource, setDataSource] = useState([])
  const [selectedStudents, setSelectedStudents] = useState([])

  const showModalHealthResults = (items) => {
    setSelectedStudents(items)
    setVisibleModalHealthResults(true)
  }

  return (
    <Layout>
      <Page>
        <Header>
          <h2>Kết quá khám sức khoẻ</h2>

          <Actions>
            <div />
            <ButtonCreateHealthResults setDataSource={setDataSource} />
            <Button
              onClick={() => {
                showModalHealthResults(dataSource)
              }}
            >
              In toàn bộ
            </Button>
          </Actions>
        </Header>

        <TableHealthResults
          dataSource={dataSource}
          showModalHealthResults={showModalHealthResults}
        />
      </Page>

      <ModalHealthResults
        visible={visibleModalHealthResults}
        setVisible={setVisibleModalHealthResults}
        printData={selectedStudents}
      />
    </Layout>
  )
}

export default Element
