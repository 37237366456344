import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import 'antd/dist/reset.css';
import './index.css'
import Dashboard from '@/pages/Dashboard'
import Students from '@/pages/Students'
import Staffs from '@/pages/Staffs'
import HealthResults from '@/pages/HealthResults'
import reportWebVitals from './reportWebVitals'

const router = createBrowserRouter([
  {
    path: '/',
    element: <HealthResults />,
  },
  {
    path: '/staffs',
    element: <Staffs />,
  },
  {
    path: '/employees',
    element: <Staffs />,
  },
  {
    path: '/health-results',
    element: <HealthResults />,
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <RouterProvider router={router} />
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
