import styled from 'styled-components'

export const Page = styled.div`
  background-color: #ffffff;
  border-radius: 0.25rem;
  box-shadow: rgb(0 0 0 / 2%) 0 0 0.75rem;
  padding: 1rem;
`

export const Header = styled.header`
  margin-bottom: 1rem;

  h2 {
    margin-bottom: 0.5rem;
  }
`

export const Actions = styled.div`
  display: grid;
  grid-template-columns: 1fr 13.25rem 7.5rem;
  grid-gap: 1rem;
  align-items: center;
`