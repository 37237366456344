import { Link } from 'react-router-dom'
import ButtonCreatePhotos from '@/components/ButtonCreatePhotos'
import { Layout, Header, Main, Footer, Container } from './styles'
import src from '@/assets/logo.png'

const Element = ({ children }) => {
  return (
    <Layout>
      <Container>
        <Header>
          <nav>
            <Link to="/">
              <img src={src} alt="" />
            </Link>

            
          </nav>

          <ButtonCreatePhotos />
        </Header>

        <Main>{children}</Main>

        <Footer>Wellspring International Bilingual School</Footer>
      </Container>
    </Layout>
  )
}

export default Element
