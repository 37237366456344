import { useMemo } from "react"
import moment from "moment"
import { Table, Button } from "antd"
import { HeartOutlined } from "@ant-design/icons"
import Student from "./Student"

const Element = ({ dataSource, showModalHealthResults }) => {
  const columns = useMemo(() => {
    return [
      {
        title: "Học sinh",
        dataIndex: "student",
        key: "student",
        width: "30%",
        render: (text, rowData, index) => {
          return <Student data={rowData} />
        },
      },
      {
        title: "Lớp",
        dataIndex: "className",
        key: "className",
        width: "15%",
      },
      {
        title: "Sinh nhật",
        dataIndex: "birthday",
        key: "birthday",
        width: "25%",
        render: (text) => {
          return <div>{moment(text).format("DD/MM/YYYY")}</div>
        },
      },
      {
        title: "",
        dataIndex: "actions",
        key: "actions",
        width: "15%",
        render: (text, rowData, index) => {
          return (
            <Button
              icon={<HeartOutlined />}
              onClick={() => {
                showModalHealthResults([rowData])
              }}
            />
          )
        },
      },
    ]
  }, [])

  return <Table rowKey="code" dataSource={dataSource} columns={columns} />
}

export default Element
