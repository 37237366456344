import styled from 'styled-components'

export const Section = styled.section`
  background-color: #ef5124;
  font-size: 0.75rem;
  border-radius: 1rem;
  overflow: hidden;

  .border-top, .border-left {
    border-color: #ffffff;
  }
`

export const Main = styled.main`
  display: grid;
  grid-template-columns: 2fr 1fr;

  .ant-col {
    padding: 0.5rem 0.625rem;
  }
`

export const Footer = styled.footer`
  color: #ef5124;
  font-size: 0.75rem;
  text-align: center;
  margin-top: 0.25rem;

  span {
    display: inline-block;
    width: 2.5rem;
  }
`
