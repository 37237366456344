import React, { useMemo } from 'react'
import { Row, Col } from 'antd'
import {
  PhoneOutlined,
  MailOutlined,
  GlobalOutlined,
  EnvironmentOutlined
} from '@ant-design/icons'
import Section01 from './Section01'
import Section02 from './Section02'
import Section03 from './Section03'
import Section04 from './Section04'
import Section05 from './Section05'
import Section06 from './Section06'
import Section07 from './Section07'
import Section08 from './Section08'
import Section09 from './Section09'
import {
  Result,
  Header,
  Main,
  Footer,
  Brands,
  TitleVI,
  TitleEN,
  FooterTitle,
  FooterSubTitle,
  FooterItem
} from './styles'
import src01 from './logo.jpg'
import src02 from '@/assets/happy.png'

const HealthTestResult = ({ data = {} }) => {
  const filename = useMemo(() => {
    return `${data.code} - ${data.name}`
  }, [data])

  return (
    <Result className="print" data-filename={filename} data-class={data.class}>
      <Header>
        <Brands>
          <img className="img-01" src={src01} alt="wellspring" />

          <img className="img-02" src={src02} alt="wellspring" />
        </Brands>

        <div>
          <TitleVI>BÁO CÁO KẾT QUẢ<br />SỨC KHỎE HỌC SINH ĐẦU NĂM / NĂM HỌC 2023-2024</TitleVI>
          <TitleEN>HEALTH CHECK REPORT / SCHOOL YEAR 2023-2024</TitleEN>
        </div>
      </Header>

      <Main>
        <Section01 data={data} />

        <Section02 data={data} />

        <Row gutter={12}>
          <Col span={10}>
            <Section03 data={data} />
          </Col>

          <Col span={14}>
            <Section04 data={data} />
          </Col>
        </Row>

        <Section05 data={data} />

        <Section06 data={data} />

        <Section07 data={data} />

        <Section08 data={data} />

        <Section09 data={data} />
      </Main>

      <Footer>
        <FooterTitle>
          WELLSPRING SAIGON INTERNATIONAL BILINGUAL SCHOOL
        </FooterTitle>
        
        <Row>
          <Col span={12}>
            <Row>
              <Col span={12}>
                <FooterSubTitle>
                  Wellspring Saigon
                </FooterSubTitle>
              </Col>
              <Col span={12}>
                <FooterSubTitle>
                  Admissions
                </FooterSubTitle>
              </Col>
              <Col span={12}>
                <FooterItem>
                  <PhoneOutlined /> (+84) 28 3840 9292
                </FooterItem>
              </Col>
              <Col span={12}>
                <FooterItem>
                  <PhoneOutlined /> (+84) 937 099 229
                </FooterItem>
              </Col>
              <Col span={12}>
                <FooterItem>
                  <MailOutlined /> wssg@wellspringsaigon.edu.vn
                </FooterItem>
              </Col>
              <Col span={12}>
                <FooterItem>
                  <MailOutlined /> admissions@wellspringsaigon.edu.vn
                </FooterItem>
              </Col>
              <Col span={24}>
                <FooterItem>
                  <EnvironmentOutlined /> www.wellspringsaigon.edu.vn
                </FooterItem>
                <FooterItem>
                  <GlobalOutlined /> 92 Nguyen Huu Canh St., Ward 22, Binh Thanh
                  Dist, Ho Chi Minh city
                </FooterItem>
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <Row>
              <Col span={12}>
                <FooterSubTitle>
                  Wellspring Hanoi
                </FooterSubTitle>
              </Col>
              <Col span={12}>
                <FooterSubTitle>
                  Admissions
                </FooterSubTitle>
              </Col>
              <Col span={12}>
                <FooterItem>
                  <PhoneOutlined /> (+84) 24 7305 8668
                </FooterItem>
              </Col>
              <Col span={12}>
                <FooterItem>
                  <PhoneOutlined /> (+84) 973 759 229
                </FooterItem>
              </Col>
              <Col span={12}>
                
              </Col>
              <Col span={12}>
                <FooterItem>
                  <MailOutlined /> tuyensinh@wellspringsaigon.edu.vn
                </FooterItem>
              </Col>
              <Col span={24}>
                <FooterItem>
                  <EnvironmentOutlined /> www.wellspring.edu.vn
                </FooterItem>
                <FooterItem>
                  <GlobalOutlined /> 95 Ai Mo St., Bo De ward, Long Bien Dist., Ha Noi
                </FooterItem>
              </Col>
            </Row>
          </Col>
        </Row>
      </Footer>

      <div className='deco-01' />
      <div className='deco-02' />
      <div className='deco-03' />
    </Result>
  )
}

export default HealthTestResult
