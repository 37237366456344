import React from 'react'
import moment from 'moment'
import { Row, Col } from 'antd'
import { Section, Header, Main, Title } from './styles'

const Section01 = ({ data }) => {
  return (
    <Section>
      <Header>
        <Title><strong>Thông tin học sinh</strong> / Student Information</Title>
      </Header>
      <Main>
        <Row>
          <Col span={3}>
            <div className="text-blue bold">Họ và Tên</div>
            <div className="text-blue italic">Name</div>
          </Col>
          <Col span={6} className="flex-center">
            <div className="text-capitalize bold size-14">{data.name}</div>
          </Col>
          <Col span={3} className="border-left">
            <div className="text-blue bold">Lớp</div>
            <div className="text-blue italic">Class</div>
          </Col>
          <Col span={4} className="flex-center">
            <div className="bold size-14">{data.className}</div>
          </Col>
          <Col span={4} className="border-left">
            <div className="text-blue bold">Mã số Học sinh</div>
            <div className="text-blue italic">Student ID</div>
          </Col>
          <Col span={4} className="flex-center">
            <div className="bold size-14">{data.code}</div>
          </Col>
        </Row>

        <Row>
          <Col span={3}>
            <div className="text-blue bold">Giới tính</div>
            <div className="text-blue italic">Gender</div>
          </Col>
          <Col span={6} className="flex-center">
            <div className="bold size-14">{data.gender}</div>
          </Col>
          <Col span={3} className="border-left">
            <div className="text-blue bold">Ngày sinh</div>
            <div className="text-blue italic">DOB</div>
          </Col>
          <Col span={4} className="flex-center">
            <div className="bold size-14">{moment(data.birthday).format('DD/MM/YYYY')}</div>
          </Col>
          <Col span={4} className="border-left">
            <div className="text-blue bold">Ngày kiểm tra</div>
            <div className="text-blue italic">Test Date</div>
          </Col>
          <Col span={4} className="flex-center">
            <div className="bold size-14">08/2023</div>
          </Col>
        </Row>
      </Main>
    </Section>
  )
}

export default Section01
