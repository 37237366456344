import styled from 'styled-components'

export const Element = styled.div`
  position: relative;
  background-color: #112d51;
  text-align: center;
  overflow: hidden;

  .deco-01 {
    position: absolute;
    top: 55%;
    left: -5rem;
    width: 8rem;
    height: 8rem;
    background-color: #ffcc00;
    border-radius: 2rem;
    transform: rotate(45deg);
  }

  .deco-02 {
    position: absolute;
    top: 30%;
    right: -6rem;
    width: 10rem;
    height: 10rem;
    background-color: #ff4c00;
    border-radius: 2rem;
    transform: rotate(45deg);
  }
`

export const Content = styled.div`
  padding-top: 0.5rem;
  padding-bottom: 4.5rem;

  img {
    display: block;
    height: auto;
    margin: auto;
  }

  img:nth-child(1) {
    width: 17rem;
    transform: translateX(-12px);
    margin-bottom: 0.5rem;
  }

  img:nth-child(2) {
    width: auto;
    height: 22.5rem;
  }
`

export const Name = styled.div`
  height: 3.5rem;
  color: #ffffff;
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.2;
  margin-top: 1.5rem;
`

export const StudentId = styled.div`
  color: #ffffff;
  background-color: ${props => props.color};
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 4.5rem;
`



