import { Section, Name, Code } from './styles'

const Element = ({ data }) => {
  
  return (
    <Section>
      <main>
        <Name>{data.name}</Name>
        <Code>{data.code}</Code>
      </main>
    </Section>
  )
}

export default Element
