import React from 'react'
import { Row, Col } from 'antd'
import { Section, Header, Main, Title } from './styles'

const Section03 = ({ data }) => {
  return (
    <Section>
      <Header>
        <Title>Kết quả khám huyết áp</Title>
      </Header>
      <Main>
        <Row>
          <Col span={12} className="border-bottom">
            <div className="text-blue semibold">Tâm thu</div>
          </Col>
          <Col span={12} className="border-right border-bottom">
            <div className="text-blue bold">{data['Tâm Thu']}</div>
          </Col>
          <Col span={12}>
            <div className="text-blue semibold">Tâm trương</div>
          </Col>
          <Col span={12} className="border-right">
            <div className="text-blue bold">{data['Tâm Trương']}</div>
          </Col>
        </Row>

        <Row>
          <Col span={12} className="flex-center">
            <div className="text-blue semibold">Nhịp tim</div>
            <div className="text-blue italic">(lần/phút)</div>
          </Col>

          <Col span={12} className="flex-center">
            <div className="text-blue bold">{data['Mạch']}</div>
          </Col>
        </Row>
      </Main>
    </Section>
  )
}

export default Section03
