import styled from 'styled-components'

export const Section = styled.section`
  background-color: #fffcec;
  font-size: 0.75rem;
  border: 0.125rem solid #bfd330;
  border-radius: 1rem;
  margin-bottom: 0.75rem;
  overflow: hidden;

  .border-right, .border-bottom {
    border-color: #bfd330;
  }
`

export const Header = styled.header`
  background-color: #bfd330;
  padding: 0.75rem 1rem;
`

export const Main = styled.main`

  .ant-col {
    padding: 0.5rem 0.625rem;
  }
`

export const Title = styled.h6`
  color: #172b54;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 0;
`
