import styled from 'styled-components'

export const Section = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  align-items: center;

  img {
    width: 100%;
    height: auto;
    border-radius: 50%;
  }
`

export const Name = styled.h6`
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 0;
`

export const Code = styled.div`
  font-weight: 300
`
