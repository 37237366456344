import React from "react"
import { Row, Col } from "antd"
import { Section, Main, Footer } from "./styles"

const Section09 = ({ data }) => {
  return (
    <React.Fragment>
      <Section>
        <Main>
          <div>
            <Row>
              <Col span={6}>
                <div className="text-white">BMI (kg/m2) <sup>(1)</sup></div>
                <div className="text-white">Chỉ số khối cơ thể</div>
                <div className="text-white italic">Body Mass Index</div>
              </Col>

              <Col span={2} className="flex-center center">
                <div className="text-white bold">{data["BMI (kg/m2)"]}</div>
              </Col>

              <Col span={6} className="border-left">
                <div className="text-white">PBF (%) <sup>(2)</sup></div>
                <div className="text-white">Phần trăm chất béo</div>
                <div className="text-white italic">Percent Body Fat</div>
              </Col>

              <Col span={2} className="flex-center center">
                <div className="text-white bold">{data["PBF"]}</div>
              </Col>

              <Col span={5} className="flex-center border-left">
                <div className="text-white">Điểm số cơ thể <sup>(2)</sup></div>
                <div className="text-white italic">Body Score (points)</div>
              </Col>

              <Col span={3} className="flex-center center">
                <div className="text-white bold">{data["bodyScore"]}/100</div>
              </Col>
            </Row>

            <Row>
              <Col span={24} className="border-top">
                <div className="text-white">
                  Nhận xét chung của Phòng Y tế học đường:
                </div>
                <div className="text-white">{data["Dặn dò của Trường"]}</div>
              </Col>
            </Row>
          </div>

          <div>
            <Row>
              <Col span={12} className="border-left">
                <div className="text-white">
                  Kết luận dinh dưỡng <sup>(1)</sup>
                </div>
              </Col>

              <Col span={12}>
                <div className="text-white bold">
                  {data["Kết Luận Dinh Dưỡng"]}
                </div>
              </Col>

              <Col span={12} className="border-left">
                <div className="text-white">
                  Phân loại dinh dưỡng <sup>(1)</sup>
                </div>
              </Col>

              <Col span={12}>
                <div className="text-white bold">
                  {data["Phân loại tình trạng thể lực DD"]}
                </div>
              </Col>

              <Col span={12} className="border-left">
                <div className="text-white">
                  Tình trạng bệnh tật <sup>(1)</sup>
                </div>
              </Col>

              <Col span={12}>
                <div className="text-white bold">
                  {data["Phân loại tình trạng bệnh tật"]}
                </div>
              </Col>

              <Col span={12} className="border-left">
                <div className="text-white">
                  Phân loại sức khỏe <sup>(1)</sup>
                </div>
              </Col>

              <Col span={12}>
                <div className="text-white bold">
                  {data["Phân loại sức khỏe"]}
                </div>
              </Col>

              <Col span={12} className="border-left">
                <div className="text-white">Đề nghĩ của Bác sĩ</div>
              </Col>

              <Col span={12}>
                <div className="text-white size-10" style={{ height: '45px' }}>
                  {data["Dặn dò của BS"]}
                </div>
              </Col>
            </Row>
          </div>
        </Main>
      </Section>

      <Footer>
        <sup>(1)</sup> Tham khảo Phụ lục trang sau <span /> <sup>(2)</sup> Các chỉ số này
        chỉ có tính chất tham khảo tương đối để cơ thể khoẻ đẹp hơn
      </Footer>
    </React.Fragment>
  )
}

export default Section09
