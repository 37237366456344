import styled from 'styled-components'

export const Result = styled.div`
  position: relative;
  padding: 1rem;
  overflow: hidden;

  .deco-01 {
    position: absolute;
    top: 0;
    right: 30%;
    width: 8rem;
    height: 8rem;
    background-color: #002857;
    border-radius: 2rem;
    transform: translateY(-5rem) rotate(45deg);
  }

  .deco-02 {
    position: absolute;
    bottom: 50%;
    left: 0;
    width: 8rem;
    height: 8rem;
    background-color: #facd01;
    border-radius: 2rem;
    transform: translateX(-6.5rem) rotate(45deg);
  }

  .deco-03 {
    position: absolute;
    bottom: 4%;
    right: 0;
    width: 8rem;
    height: 8rem;
    background-color: #fa4d09;
    border-radius: 2rem;
    transform: translateX(6rem) rotate(45deg);
  }

  .ant-row, .ant-col {
    font-size: unset;
  }
`

export const Header = styled.header`
  margin-bottom: 1.5rem;
`

export const Brands = styled.div`
  display: flex;
  justify-content: space-between;

  .img-01 {
    width: auto;
    height: 7rem;
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }

  .img-02 {
    width: auto;
    height: 5rem;
  }
`

export const Main = styled.main`
  padding-left: 2rem;
  padding-right: 2rem;
`

export const Footer = styled.footer`
  border-top: 0.125rem solid #eeeeee;
  padding-top: 0.5rem;
  margin-top: 0.5rem;
`

export const TitleVI = styled.h3`
  color: #132d52;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  line-height: 1.1;
  margin-bottom: 0.25rem;
`

export const TitleEN = styled.h4`
  color: #03687f;
  font-size: 1.5rem;
  font-style: italic;
  text-align: center;
  margin-bottom: 0;
`

export const FooterTitle = styled.h5`
  color: #ff4c00;
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 0;
`

export const FooterSubTitle = styled.h6`
  color: #132d52;
  font-size: 1rem;
  margin-bottom: 0;
`

export const FooterItem = styled.div`
  font-size: 0.75rem;
  line-height: 2;

  .anticon {
    color: #ffffff;
    background-color: #ff4c00;
    border-radius: 0.25rem;
    padding: 0.125rem;
    margin-right: 0.25rem;
  }
`
