import React from 'react'
import readXlsxFile from 'read-excel-file'
import { Upload, Button, message } from 'antd'

const beforeUpload = file => {
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    message.error('Tập tin vượt quá 2MB')
    return false
  }

  return isLt2M
}

const decodeData = data => {
  const timestamp = Math.round((data[4] - (25567 + 2)) * 86400 * 1000)
  if (data[2] === 'Lê Hoàng Lan Khuê') {
    // console.log(data)
    console.log(data[38])
  }
  return {
    code: data[1],
    name: data[2],
    className: data[3],
    birthday: timestamp,
    weight: data[5],
    height: data[6],
    gender: data[7] ? 'Nam' : 'Nữ',
    bodyWater: data[9],
    bodyWaterLimit: data[10],
    protein: data[11],
    proteinLimit: data[12],
    minerals: data[13],
    mineralsLimit: data[14],
    bodyFat: data[15],
    bodyFatLimit: data[16],
    targetWeight: data[17],
    weightControl: data[18] > 0 ? `+${data[18]}` : data[18],
    fatControl: data[19] > 0 ? `+${data[19]}` : data[19],
    muscleControl: data[20] > 0 ? `+${data[20]}` : data[20],
    'BMI (kg/m2)': data[21],
    PBF: data[22],
    bodyScore: data[23],
    "Mạch": data[24],
    "Tâm Trương": data[25],
    "Tâm Thu": data[26],
    'CXK-CS': data[27] || 'Chưa nghi nhận bất thường',
    'CXK-CS Khác': data[28] || 'Không có',
    Răng: data[29] || 'Chưa nghi nhận bất thường',
    'Răng Khác': data[30] || 'Không có',
    'Mắt Phải KK/10': data[31],
    'Mắt Phải CK/10': data[32],
    'Mắt Trái KK/10': data[33],
    'Mắt Trái CK/10': data[34],
    'TKX': data[35] || 'Không có',
    'Bệnh về mắt': data[36] || 'Không có',
    'CQSD Tiết Niệu': data[37] || 'Chưa nghi nhận bất thường',
    'Da Liễu': data[38] || 'Chưa nghi nhận bất thường',
    'Thần Kinh': data[39] || 'Chưa nghi nhận bất thường',
    'Hô Hấp': data[40] || 'Chưa nghi nhận bất thường',
    'Tim Mạch': data[41] || 'Chưa nghi nhận bất thường',
    'Tiêu Hóa': data[42] || 'Chưa nghi nhận bất thường',
    'Tai Mũi Họng': data[43] || 'Chưa nghi nhận bất thường',
    'Tai Mũi Họng Khác': data[44] || 'Không có',

    'Bệnh Nội Tiết': data[45],
    DTBS: data[46],
    'Kết Luận Chiều cao': data[47],
    'Kết Luận Dinh Dưỡng BMI': data[48],
    'Kết Luận Dinh Dưỡng': data[49],
    'Phân loại tình trạng thể lực DD': data[50],
    'Phân loại tình trạng bệnh tật': data[51],
    'Phân loại sức khỏe': data[52],
    'Dặn dò của BS': data[53],
    'Dặn dò của Trường': data[54]
  }
}

const Element = ({ setDataSource }) => {
  const customRequest = info => {
    const { file } = info

    readXlsxFile(file).then(rows => {
      const dataSource = []

      for (let i = 8; i < rows.length; i++) {
        const data = rows[i]
        const studentData = decodeData(data)
        
        dataSource.push(studentData)
      }

      setDataSource(dataSource)
    })
  }

  return (
    <Upload
      name="file"
      accept=".xlsx"
      multiple={false}
      showUploadList={false}
      beforeUpload={beforeUpload}
      customRequest={customRequest}
    >
      <Button>Tải thông tin khám sức khỏe</Button>
    </Upload>
  )
}

export default Element
