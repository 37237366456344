import React from 'react'
import { Row, Col } from 'antd'
import { Section, Header, Main, Title } from './styles'

const Section04 = ({ data }) => {

  return (
    <Section>
      <Header>
        <Title>Kết quả khám mắt</Title>
      </Header>
      <Main>
        <Row>
          <Col span={4} className="border-bottom border-right">
            <div className="text-blue">Có Kính</div>
          </Col>

          <Col span={3} className="border-bottom">
            <div className="text-blue">Mắt trái</div>
          </Col>

          <Col span={2} className="border-bottom">
            <div className="text-blue bold">{data['Mắt Trái CK/10']}</div>
          </Col>

          <Col span={3} className="border-bottom">
            <div className="text-blue">Mắt phải</div>
          </Col>

          <Col span={2} className="border-bottom border-right">
            <div className="text-blue bold">{data['Mắt Phải CK/10']}</div>
          </Col>

          <Col span={6} className="border-bottom">
            <div className="text-blue">Bệnh về mắt khác</div>
          </Col>

          <Col span={4} className="border-bottom">
            <div className="text-blue bold">{data['Bệnh về mắt']}</div>
          </Col>
        </Row>

        <Row>
          <Col span={4} className="border-right">
            <div className="text-blue">Không Kính</div>
          </Col>

          <Col span={3}>
            <div className="text-blue">Mắt trái</div>
          </Col>

          <Col span={2}>
            <div className="text-blue bold">{data['Mắt Trái KK/10']}</div>
          </Col>

          <Col span={3}>
            <div className="text-blue">Mắt phải</div>
          </Col>

          <Col span={2} className="border-right">
            <div className="text-blue bold">{data['Mắt Phải KK/10']}</div>
          </Col>

          <Col span={6}>
            <div className="text-blue">Tật khúc xạ</div>
          </Col>

          <Col span={4}>
            <div className="text-blue bold">{data['TKX']}</div>
          </Col>
        </Row>
      </Main>
    </Section>
  )
}

export default Section04
